// extracted by mini-css-extract-plugin
export var back = "PlasmicBlogPostPage-module--back--255fc";
export var copyright = "PlasmicBlogPostPage-module--copyright--efff3";
export var freeBox__i8CjD = "PlasmicBlogPostPage-module--freeBox__i8CjD--354e7";
export var freeBox__tfiS4 = "PlasmicBlogPostPage-module--freeBox__tfiS4--95092";
export var freeBox__vcUz7 = "PlasmicBlogPostPage-module--freeBox__vcUz7--8a03e";
export var freeBox__wicIv = "PlasmicBlogPostPage-module--freeBox__wicIv--e1620";
export var header = "PlasmicBlogPostPage-module--header--6528e";
export var root = "PlasmicBlogPostPage-module--root--ddf87";
export var slotTargetContent = "PlasmicBlogPostPage-module--slotTargetContent--78d19";
export var slotTargetSubheader = "PlasmicBlogPostPage-module--slotTargetSubheader--2aaef";
export var slotTargetSubtitle = "PlasmicBlogPostPage-module--slotTargetSubtitle--63071";
export var slotTargetTitle = "PlasmicBlogPostPage-module--slotTargetTitle--2ed93";
export var subtitle = "PlasmicBlogPostPage-module--subtitle--2469e";
export var title = "PlasmicBlogPostPage-module--title--1c9f0";