import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import PlasmicBlogPostPage from "../components/plasmic/isaacpalka_com/PlasmicBlogPostPage" // plasmic-import: As-VifLuol/component
import { createSubheader } from "../utils/createSubheader"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const {
    frontmatter: { title, date },
    html,
    timeToRead,
    excerpt,
  } = markdownRemark
  const subtitle = "by Isaac Palka"
  const subheader = createSubheader(date, timeToRead)

  return (
    <>
      <Helmet>
        <title>{title} | Tech-nically Speaking</title>
        <meta name="description" content={excerpt} />
        <meta charSet="utf-8" />
      </Helmet>
      <PlasmicBlogPostPage
        title={title}
        subtitle={subtitle}
        subheader={subheader}
        content={<div dangerouslySetInnerHTML={{ __html: html }} />}
      />
    </>
  )
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMM DD, YYYY")
        path
        title
      }
      timeToRead
      excerpt
    }
  }
`
